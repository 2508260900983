<template>
    <div id="app">
            <div class="top-bar">
                <div class="logo-container">
                    <img
                        class="logo"
                        src="logo.svg"
                        alt="Sourcetoad"
                    />
                </div>
                <ul class="nav-bar">
                    <li>
                        <a
                            href="https://github.com/sourcetoad"
                        >
                            GitHub
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://sourcetoad.com"
                        >
                            Sourcetoad
                        </a>
                    </li>
                </ul>
            </div>
        <header>
            <div class="content">
                <h1>Open source at Sourcetoad</h1>
            </div>
        </header>
        <section id="synopsis">
            <div class="content">
                <p class="blurb">
                    Here at Sourcetoad, we're into open source.
                    We love taking part in the open community
                    by upstreaming fixes to projects we use,
                    and we'd like to share some projects we've created with you.
                    Let's build open source together&mdash;patches are always welcome!
                </p>
                <h2>Popular projects</h2>
                <project-table />
            </div>
        </section>
        <section id="details">
            <div class="content">
                <project-section
                    :key="project.name"
                    :project="project"
                    v-for="project in projects"
                />
            </div>
        </section>
        <footer>
            <div class="content">
                &copy; Sourcetoad, LLC
            </div>
        </footer>
    </div>
</template>

<script>
    import projects from '@/projects';
    import ProjectTable from '@/components/ProjectTable';
    import ProjectSection from '@/components/ProjectSection';

    export default {
        name: 'App',
        components: {
            ProjectTable,
            ProjectSection,
        },
        data() {
            return {
                projects,
                ticking: false,
            };
        },
    }
</script>
