<template>
    <div class="project-table">
        <div
            class="project"
            v-for="project in projects"
            :key="project.key"
        >
            <a class="header-link" :href="`#${project.name}`">
                {{ project.prettyName }}
            </a>
        </div>
    </div>
</template>

<script>
    import projects from '@/projects';

    export default {
        name: "ProjectTable",
        data() {
            return {
                projects,
            };
        }
    }
</script>
