<template>
    <div
        class="readme"
        :class="{expanded}"
    >
        <div class="header">README.md</div>
        <div class="content">
            <component
                :is="`${project.name}-readme`"
                v-if="project.readme"
            />
        </div>
        <div class="bottom-controls">
            <div
                class="button"
                @click="expanded = !expanded; $emit('toggleExpanded', expanded)"
            />
        </div>
    </div>
</template>

<script>
    import {defineAsyncComponent} from 'vue';

    import projects from '@/projects';

    const readmes = {};
    projects.forEach(async project => {
        readmes[`${project.name}-readme`] = defineAsyncComponent(() => import(`@/assets/project-readmes/${project.name}.md`));
    });

    export default {
        name: 'Readme',
        props: {
            project: {
                type: Object,
                required: true,
            },
        },
        components: {
            ...readmes,
        },
        data() {
            return {
                expanded: false,
            };
        }
    };
</script>
