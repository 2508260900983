import mitt from 'mitt';

export default class CursorWatcher {
    /** @private */
    emitter;

    /** @property {function} */
    on;

    /** @property {function} */
    off;

    /**
     * @param {?boolean} touchSensitive
     */
    constructor(touchSensitive = false) {
        this.emitter = mitt();
        this.on = this.emitter.on;
        this.off = this.emitter.off;

        window.addEventListener(
            'mousemove',
            this.handleMouseMove.bind(this)
        );

        if (touchSensitive) {
            window.addEventListener(
                'touchstart',
                this.handleTouch.bind(this)
            );
        }

        window.addEventListener(
            'touchmove',
            this.handleTouch.bind(this)
        );
    }

    handleMouseMove(event) {
        const normalizedX = ((event.clientX / window.innerWidth) - 0.5) * 2;
        const normalizedY = ((1 - (event.clientY / window.innerHeight)) - 0.5) * 2;
        this.emitter.emit('move', {x: normalizedX, y: normalizedY});
    }

    handleTouch(event) {
        const normalizedX = ((event.changedTouches[0].clientX / window.innerWidth) - 0.5) * 2;
        const normalizedY = ((event.changedTouches[0].clientY / window.innerHeight) - 0.5) * -2;
        this.emitter.emit('move', {x: normalizedX, y: normalizedY});
    }
}
