import { createApp } from 'vue';
import App from '@/App';
import CursorWatcher from '@/plugins/CursorWatcher';
import '@/styles/index.scss';
import GLManager from '@/plugins/GLManager';

const app = createApp(App);

app.use(CursorWatcher);
app.use(GLManager);

app.mount('#app');

