export default class GLManager {
    /** @property {number} */
    renderersAvailable = 8;

    /** @property {function} */
    listeners = [];

    isRendererAvailable() {
        return !!this.renderersAvailable;
    }

    requestRenderer(callback) {
        if (!this.isRendererAvailable()) {
            this.listeners.push(callback);

            return;
        }

        --this.renderersAvailable;
        callback();
    }

    releaseRenderer() {
        ++this.renderersAvailable;
        if (this.listeners.length > 0) {
            this.listeners.unshift()();

            --this.renderersAvailable;
        }
    }
}
