<template>
    <div class="project-section">
        <div
            class="graphic"
            :style="{
                transform: `translateY(${graphicTranslation}px)`,
            }"
        >
            <package-animation
                ref="packageAnimation"
                :icon="project.icon"
            />
        </div>
        <div :id="project.name" class="text">
            <h2>
                {{ project.prettyName }}
                <a class="repo-link" :href="project.repository">
                    <img class="github-icon" src="github.svg" alt="Github logo" />
                </a>
            </h2>
            <readme
                v-if="project.readme"
                :project="project"
                @toggleExpanded="handleScroll"
            />
        </div>
    </div>
</template>

<script>
    import PackageAnimation from '@/components/PackageAnimation';
    import Readme from '@/components/Readme';

    export default {
        name: 'ProjectSection',
        props: {
            project: {
                type: Object,
                required: true,
            },
        },
        components: {
            PackageAnimation,
            Readme,
        },
        data() {
            return {
                graphicTranslation: 0,
                scrollAnimation: null,
            };
        },
        created() {
            this.mediaQueryList = window.matchMedia('(max-width: 60rem)');
            this.isVbox = this.mediaQueryList.matches;
            this.mediaQueryList.addEventListener('change', (event) => {
                this.isVbox = event.matches;
                if (this.isVbox) {
                    this.graphicTranslation = 0;
                }
            });

            this.intersectionObserver = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    window.addEventListener('scroll', this.handleScroll);
                } else {
                    window.removeEventListener('scroll', this.handleScroll);
                }
            });
        },
        mounted() {
            this.styles = window.getComputedStyle(this.$el);
            this.paddingTop = parseInt(this.styles.getPropertyValue('padding-top').replace('px', ''));
            this.paddingBottom = parseInt(this.styles.getPropertyValue('padding-bottom').replace('px', ''));
            this.verticalPadding = this.paddingTop + this.paddingBottom;
            this.graphicHeight = this.$refs.packageAnimation.$el.clientHeight;
            this.intersectionObserver.observe(this.$el);
        },
        methods: {
            handleScroll() {
                if (!this.$el || this.scrollAnimation !== null) {
                    return;
                }

                this.scrollAnimation = window.requestAnimationFrame(() => {
                    this.scrollAnimation = null;
                    const {top, bottom, height} = this.$el.getBoundingClientRect();
                    const maxTranslation = height - this.graphicHeight - this.verticalPadding;
                    const viewportHeight = document.documentElement.clientHeight;
                    const topOfSectionVisible = viewportHeight - top;

                    if (topOfSectionVisible > 200 && topOfSectionVisible < 700) {
                        this.$refs.packageAnimation.setTime((topOfSectionVisible - 200) / 500);
                    } else if (topOfSectionVisible >= 700 && bottom >= 700) {
                        this.$refs.packageAnimation.setTime(1);
                    } else if (bottom > 200 && bottom < 700) {
                        this.$refs.packageAnimation.setTime((bottom - 200) / 500);
                    }

                    if (top < 0 && !this.isVbox) {
                        this.graphicTranslation = -top < maxTranslation ? -top : maxTranslation;
                    } else {
                        this.graphicTranslation = 0;
                    }
                });
            }
        }
    };
</script>
