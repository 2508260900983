<template>
    <div
        class="package-animation"
        ref="viewport"
    />
</template>

<script>
    import {
        sRGBEncoding,
        AnimationMixer,
        MeshBasicMaterial,
        Scene,
        TextureLoader,
        WebGLRenderer,
    } from 'three';
    import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';

    const clipLength = 2.4999999;

    export default {
        name: 'PackageAnimation',
        props: {
            icon: {
                default: 'blue-package',
            },
        },
        data() {
            return {
                loading: true,
                error: null,
                loadedBytes: 0,
                totalBytes: 0,
                visible: false,
                initialTime: 0,
                cursorAnimation: null,
                renderer: null,
            }
        },
        methods: {
            loadModel() {
                const loader = new GLTFLoader();
                loader.load(
                    'package.glb',
                    gltf => {
                        this.loading = false;

                        this.camera = gltf.cameras[0];
                        this.subjectGroup = gltf.scene.getObjectByName('SubjectParent');
                        this.cameraGroup = gltf.scene.getObjectByName('CameraParent');

                        this.cameraGroup.rotation.order = 'YXZ';
                        this.originalCameraX = this.cameraGroup.rotation.x;
                        this.originalCameraY = this.cameraGroup.rotation.y;

                        this.scene.add(gltf.scene);

                        const clips = gltf.animations;
                        this.mixer = new AnimationMixer(this.scene);
                        clips.forEach(clip => this.mixer.clipAction(clip).play());

                        this.mixer.setTime(this.initialTime);

                        this.loadIcon();
                    },
                    ({loaded, total}) => {
                        this.loadedBytes = loaded;
                        this.totalBytes = total
                    },
                    error => {
                        this.error = error
                    }
                );
            },
            loadIcon() {
                const loader = new TextureLoader();

                loader.load(
                    `project-icons/${this.icon}.png`,
                    texture => {
                        const material = new MeshBasicMaterial({
                            map: texture,
                            transparent: true,
                        });

                        const canvas = this.scene.getObjectByName('Canvas');
                        canvas.material.dispose();
                        canvas.material = material;

                        this.isLoaded = true;
                        this.render();
                    },
                    undefined,
                    error => {
                        this.error = error
                    }
                );
            },
            onCursorMove(position) {
                if (!this.isLoaded || this.cursorAnimation !== null) {
                    return;
                }

                this.cursorAnimation = requestAnimationFrame(() => {
                    this.cursorAnimation = null;
                    this.cameraGroup.rotation.x = this.originalCameraX + (position.y / 7);
                    this.cameraGroup.rotation.y = this.originalCameraY - (position.x / 7);
                    this.render();
                });
            },
            setTime(time) {
                if (!this.visible) {
                    return;
                }

                this.initialTime = clipLength * time;

                if (this.isLoaded) {
                    this.mixer.setTime(this.initialTime);
                    this.render();
                }
            },
            render() {
                if (this.renderer !== null && this.isLoaded) {
                    this.renderer.render(this.scene, this.camera);
                }
            },
            startRendering() {
                this.renderer = new WebGLRenderer({antialias: true, alpha: true});
                this.renderer.physicallyCorrectLights = true;
                this.renderer.outputEncoding = sRGBEncoding;
                this.renderer.setPixelRatio(window.devicePixelRatio);
                this.renderer.setSize(this.$refs.viewport.offsetWidth, this.$refs.viewport.offsetHeight);
                this.$refs.viewport.appendChild(this.renderer.domElement);
                this.render();
            },
            stopRendering() {
                this.$refs.viewport.removeChild(this.$refs.viewport.firstChild);
                this.renderer.dispose();
            },
        },
        mounted() {
            this.scene = new Scene();

            this.loadModel();

            this.intersectionObserver = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    this.visible = true;
                    this.$glManager.requestRenderer(() => {
                        this.startRendering();
                    });
                    this.$cursorWatcher.on('move', this.onCursorMove);
                } else {
                    this.visible = false;
                    if (this.renderer !== null) {
                        this.stopRendering();
                        this.$glManager.releaseRenderer();
                    }
                    this.$cursorWatcher.off('move', this.onCursorMove);
                }
            });
            this.intersectionObserver.observe(this.$el);
        },
    }
</script>
